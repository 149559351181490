<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="localStoredValue"
    ></fieldsRows>
    <explorerCollectStatistics v-model="statistic" :result="result">
    </explorerCollectStatistics>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import teststepDefault from '@/cfg/teststep-default.json';
import explorerCollectStatistics from '@/components/legacy/explorerCollectStatistics.vue';

export default {
  name: 'StepExplorerDNS',
  components: {fieldsRows, explorerCollectStatistics},
  data() {
    return {};
  },
  props: ['result', 'stepStoredValue', 'stepType', 'stepID'],
  computed: {
    statistic: {
      get() {
        return this.stepStoredValue.statistic;
      },
      set(newVal) {
        this.$set(this.stepStoredValue, 'statistic', newVal);
        this.$emit('input', this.stepStoredValue);
      },
    },
    localStoredValue: {
      get() {
        const localStoredValue = this.deepCopy(this.stepStoredValue);
        if (this.setDefaultValues(localStoredValue, teststepDefault[this.stepType])) {
          this.$emit('stored-value-changed', localStoredValue);
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        this.$emit('stored-value-changed', newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: 'line',
            fields: {
              startWithinMinutes: {
                type: 'number',
                name: 'Start Within Minutes',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {
                  style: 'max-width: 170px;',
                },
              },
              finishedWithinMinutes: {
                type: 'number',
                name: 'Finished Within Minutes',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {
                  style: 'max-width: 170px;',
                },
              },
              maxDuration: {
                type: 'number',
                name: 'Maximum Duration (s)',
                fieldAttrInput: {
                  class: '',
                },
                colAttrs: {
                  style: 'max-width: 170px;',
                },
              },
            },
          },
          serverLine: {
            type: 'line',
            fields: {
              ip: {
                type: 'select',
                options: ['ipv4', 'ipv6'],
                name: 'IP Version',
                fieldAttrInput: {
                  class: 'required',
                },
                colAttrs: {style: 'max-width:150px', class: ''},
              },
              useDefaultDNS: {
                type: 'switch',
                name: 'Use system default DNS server',
                colAttrs: {cols: 2, class: '', style: 'position: relative; bottom: 12px;'},
              },
            },
          },
          domainLine: {
            type: 'line',
            fields: {
              recordType: {
                type: 'combobox',
                options: this.result.additional.dnsRecordType,
                name: 'Record Type',
                fieldAttrInput: {
                  class: 'required',
                },
                colAttrs: {style: 'max-width:250px', class: ''},
              },
              domain: {
                type: 'text',
                name: 'Query',
                fieldAttrInput: {
                  class: 'required',
                },
                colAttrs: {
                  style: 'max-width: 800px;',
                },
              },
            },
          },
          res: {
            type: 'text',
            inputFieldFormatter: 'explorerOnError',
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        //contentID: this.stepType + '_' + this.stepID,
        contentID: this.stepType,
      };

      if (!this.localStoredValue.useDefaultDNS) {
        templateContent.fields.serverLine.fields.server = {
          type: 'text',
          name: 'DNS',
          fieldAttrInput: {},
          inputFieldFormatter: 'ip4ip6ValidatedInput',
          colAttrs: {
            style: 'max-width: 740px;',
          },
        };
        templateContent.fields.serverLine.fields.port = {
          type: 'number',
          name: 'Port',
          fieldAttrInput: {
            class: '',
          },
          colAttrs: {cols: '1'},
        };
      }

      return templateContent;
    },
  },
  methods: {
    // emitUpdateFields(info) {
    //   this.$emit("updated-fields", info);
    // },
    updateFieldValue(field, value) {
      let localStoredStep = {...this.stepStoredValue};
      this.$set(localStoredStep, field, value);
      this.$emit('stored-value-changed', localStoredStep);
    },
  },
};
</script>


